video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.hero-container {
  /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
  height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

/* .hero-container > h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -100px;
} */

.full-rfm-logo {
  /* font-size: 100px; */
  margin-top: -100px;
  margin-bottom: 50px;
  max-width: 90%; /* Set the maximum width of the image to 100% */
  height: auto; /* Allow the height to adjust automatically */
}

.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: "Open Sans", sans-serif;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .full-rfm-logo {
    font-size: 70px;
    margin-top: -150px;
    margin-bottom: 50px;
  }

  .hero-container > h1 {
    font-size: 70px;
    margin-top: -50px; /* Adjust the margin-top value to bring it closer to the image */
  }
}

.full-rfm-logo {
  font-size: 70px;
  margin-top: 20px;
  margin-bottom: 50px;
  margin-left: 10px;
  margin-right: 10px;
}

@media screen and (max-width: 768px) {
  /* .hero-container {
    height: 50vh;
  } */
  .hero-container > h1 {
    font-size: 50px;
  }

  .hero-container > p {
    font-size: 30px;
    /* margin-top: -100px; */
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .hero-container {
    height: 50vh;
  }
  .full-rfm-logo {
    max-width: 80%;
    height: auto;
    display: block;
    margin: auto; /* Center the image */
  }

  .hero-container > p {
    font-size: 30px;
    /* margin-top: -180px; Adjust the margin-top to bring it closer to the image */
    margin-bottom: 16px; /* Add some space at the bottom */
  }

  .hero-btns {
    margin-top: 16px; /* Adjust the margin-top for the buttons */
    margin-bottom: 30px;
  }

  .hero-btns .btn {
    margin: 6px;
  }

  .hompage-intro p {
    font-size: 2em;
  }
}
