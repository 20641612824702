@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
  /* font-family: 'Open Sans', sans-serif; */
}

hr {
  margin: 20px;
  /* padding: 10px; */
  width: 3em;
}

.home,
.services,
.products,
.portfolio,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.contact-us-button {
  text-decoration: none;
}

/* Homepage */

.homepage-intro {
  background-color: #f7f7f7; /* Set a background color */
  text-align: center;
  padding: 50px; /* Add some padding for spacing */
  font-family: "Open Sans", sans-serif;
}

.homepage-intro p {
  font-size: 2em;
  max-width: 900px; /* Increase the maximum width */
  margin: 0 auto 100px; /* Add more bottom margin for spacing */
  color: #333;
  font-family: "Open Sans", sans-serif;
}

.homepage-portfolio-btn {
  background-color: #f2711e;
  color: #fff;
  border: none;
  padding: 20px 40px; /* Increase padding for larger size */
  font-size: 1.5em; /* Increase font size */
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s;
  text-decoration: none; /* Remove underline */
  margin-bottom: 100px;
}

.homepage-portfolio-btn:hover {
  background-color: #e07d1e; /* Change button color on hover */
}

.portfolio {
  background-image: url("/public/images/ReelFocusPatternedBackground-1.png");
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.services {
  background-image: url("/public/images/ReelFocusPatternedBackground-1.png");
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-image: url("/public/images/ReelFocusPatternedBackground-1.png");
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.contact-container {
  background-image: url(/public/images/samantha-gades-fIHozNWfcvs-unsplash.jpg);
  background-repeat: no-repeat; /* Prevents the background image from repeating */
  background-size: cover; /* Ensures the background image covers the entire container */
  background-position: center center; /* Centers the background image */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  font-family: "Open Sans", sans-serif;
}

.auth-modal {
  display: flex;
  flex-direction: column;
  align-items: center; /* Align items in the center */
  text-align: center; /* Align text in the center */
  background-color: #fff;
  border-radius: 10px;
}

.auth-modal img {
  margin: 20px 20px 40px 20px;
  max-width: 50%; /* Make sure the image doesn't overflow its container */
}

.auth-modal p {
  margin: 10px;
  padding: 10px;
  font-size: 30px;
}

.auth-modal .call-us p {
  font-size: 30px;
}

.auth-modal .phone-number p {
  font-size: 35px;
}

.auth-modal form {
  width: 100%;
  max-width: 400px;
  margin-top: 20px; /* Add some space between the image/paragraph and the form */
}

form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 30px;
  font-size: large;
}

label {
  width: 100%;
  margin-bottom: 5px;
}

input,
textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.form-button {
  background-color: rgb(247, 141, 30);
  color: #fff;
  border: none;
  padding: 12px 24px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  margin-top: 10px;
}

.form-button:hover {
  background-color: #e07d1e;
}

@media (max-width: 767px) {
  .homepage-intro {
    padding: 30px;
  }

  .homepage-intro p {
    font-size: 1.8em;
    margin: 15px;
  }
}

/* Mobile Responsiveness */
@media (max-width: 600px) {
  .auth-modal {
    margin: 0 10px;
  }

  .first-last-input {
    flex-direction: column;
  }

  .first-last-input input {
    margin: 8px 0;
  }
}

/* Services */

.services-container {
  background-image: url("/public/images/ReelFocusPatternedBackground-1.png");
  background-size: cover;
  background-repeat: no-repeat;
  color: #ffff;
}

.services-container p {
  background-color: rgb(28, 27, 27);
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: large;
  padding: 20px 80px;
}

.packages-intro {
  flex: 1;
  /* max-width: 600px; */
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0px;
  padding: 0px;
  color: #fff;
}

.packages-intro h1 {
  font-size: 4em;
  margin: 50px;
  padding: 20px;
}

.packages-intro p {
  font-size: 1.5em;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 60px;
  background-color: transparent;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
}

.services-intro p {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.image-container img {
  border-radius: 10px;
}

.weddings-business {
  display: flex;
  justify-content: space-between; /* Adjust as needed for spacing between images */
  flex-wrap: wrap; /* Allows items to wrap to the next line on smaller screens */
}

.image-container {
  flex: 0 0 48%; /* Set the initial width of each image container (adjust as needed) */
  margin-bottom: 20px; /* Add margin to create space between images */
  position: relative; /* Needed for overlay effect */
  padding: 10px; /* Add padding to create space around the image */
}

.weddings-business {
  display: flex;
  justify-content: space-between; /* Adjust as needed for spacing between images */
  flex-wrap: wrap; /* Allows items to wrap to the next line on smaller screens */
  margin: 100px;
}

.image-container {
  position: relative; /* Needed for absolute positioning of text */
  flex: 0 0 calc(48% - 0.5px); /* Set the initial width of each image container (adjust as needed) */
  margin-bottom: 1px; /* Adjust margin to reduce gap between images */
  padding: 0.5px; /* Adjust padding to create space around the image */
  transition: filter 0.3s; /* Add transition for smooth hover effect */
}

.image-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  font-size: 2em; /* Increase font size */
  font-weight: bold; /* Make the text bold */
  background-color: rgba(0, 0, 0, 0.5); /* Add a semi-transparent background */
  padding: 10px 20px; /* Add some padding around the text */
  border-radius: 5px; /* Optional: Add rounded corners */
}

.wedding-image,
.business-image {
  max-width: 100%;
  height: auto;
  display: block;
}

.wedding-image::before,
.business-image::before {
  content: "";
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
}
/* Add hover effect */
.image-container:hover {
  filter: brightness(80%); /* Darken the image on hover */
}

.wedding-image:hover::before,
.business-image:hover::before {
  opacity: 1;
}

/* Media Query for Mobile */
@media (max-width: 768px) {
  .weddings-business {
    flex-direction: column; /* Stack images on top of each other */
    align-items: center; /* Center items */
    margin: 100px; /* Remove margin */
  }

  .image-container {
    width: 100%; /* Take full width of the container */
    max-width: 500px; /* Limit the image width */
  }

  .image-container img {
    max-width: 100%; /* Make sure images don't exceed their container */
  }

  .image-container:first-child {
    margin-bottom: 20px; /* Add some space between images */
  }
}

.packages-available {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  flex: 2;
  background-color: #fff;
  padding: 30px;
}

.package-options-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -10px;
}

.package-option {
  background-color: #fff;
  color: #333;
  font-size: 20px;
  padding: 20px;
  margin: 5px;
  border-radius: 10px;
  max-width: 300px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  flex-basis: calc(
    50% - 20px
  ); /* Two packages per row with some margin in between */
}

.package-option hr {
  width: 3em; /* Adjusted width */
  margin: 0 auto; /* Centering the hr element */
}

.package-option h2 {
  font-size: 1.5em;
  font-weight: 500;
  text-align: center;
}

.package-option h3 {
  font-size: 1.2em;
  color: #333;
  text-align: center;
  padding: 30px;
}

.package-option ul {
  list-style: none;
  padding: 0;
  margin-top: 10px;
}

.package-option li {
  font-family: "Open Sans", sans-serif;
  margin: 5px;
  padding: 5px;
  font-weight: bold;
  font-size: medium;
}

.package-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 0.75em;
  background-color: rgb(59, 71, 82);
  color: #fff;
  border: none;
  /* border-radius: 5px; */
  cursor: pointer;
  transition: background-color 0.3s;
}

.package-button:hover {
  background-color: #63798d; /* Adjust the hover color */
}

.package-questions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: 
    /* top, transparent red */ linear-gradient(
      rgba(0, 0, 0, 0.45),
      rgba(0, 0, 0, 0.45)
    ),
    /* bottom, image */
      url(/public/images/kal2-visuals-6oLabfCwBME-unsplash.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  font-size: medium;
  height: 500px;
  width: auto;
  color: #fff;
}

.package-questions p {
  font-family: "Open Sans", sans-serif;
  font-size: 25px;
}

.package-questions i {
  font-family: "Open Sans", sans-serif;
}

.contact-us-button {
  font-family: "Open Sans", sans-serif;
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 1em;
  font-weight: bold;
  background-color: rgb(242, 102, 34);
  color: #fff;
  border: none;
  /* border-radius: 5px; */
  cursor: pointer;
  transition: background-color 0.3s;
}

@media (max-width: 767px) {
  .package-option {
    flex-basis: calc(
      100% - 20px
    ); /* Show one package per row on smaller screens */
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .package-option {
    flex-basis: calc(
      50% - 20px
    ); /* Show two packages per row on medium screens */
  }
}

/* Weddings Page */

.wedding-packages-head {
  flex: 1;
  /* max-width: 600px; */
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0px;
  padding: 0px;
  color: #fff;
}

.wedding-packages-head h1 {
  font-size: 4em;
  margin: 50px;
  padding: 20px;
}

.wedding-packages-intro {
  flex: 1;
  background-color: transparent;
  /* max-width: 600px; */
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 20px 20px 50px;
  padding: 0px;
  color: #fff;
}

.wedding-packages-intro p {
  background-color: transparent;
  margin: 10px;
}

.wedding-disclaimer {
  font-size: small;
}
/* Business Events  */

.business-packages-head {
  flex: 1;
  /* max-width: 600px; */
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0px;
  padding: 0px;
  color: #fff;
}

.business-packages-intro {
  flex: 1;
  background-color: transparent;
  /* max-width: 600px; */
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 20px 20px 50px;
  padding: 0px;
  color: #fff;
}

.business-packages-intro p {
  background-color: transparent;
  margin: 10px;
}

.business-disclaimer {
  font-size: small;
}
.business-event-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0; /* Add some margin for spacing */
}

.business-event-container iframe {
  width: 100%;
  max-width: 800px;
  margin-bottom: 20px;
  box-sizing: border-box;
}

@media (max-width: 767px) {
  .business-event-container iframe {
    max-width: 100%;
  }
}

/* Portfolio */

.rfm-full-logo {
  display: block;
  margin: 20px auto; /* Add margin around the logo */
  max-width: 50%; /* Make sure the logo doesn't exceed its container */
  justify-content: center;
  align-items: center;
}

.portfolio-intro {
  background-image: url("/public/images/ReelFocusPatternedBackground-1.png");
  flex: 1;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "PT Sans", sans-serif;
  margin: 0px;
  padding: 0px;
  color: #fff;
}

.portfolio-intro h1 {
  font-size: 4em;
  margin: 50px;
  padding: 20px;
}

.portfolio-intro p {
  font-size: 1.5em;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 50px;
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
}

.iframe-container h1 {
  margin: 40px;
}
.business-vids,
.wedding-vids,
.more-vids {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px; /* Adjust the spacing between the sections */
  margin-left: 20px;
  margin-right: 20px;
  margin: 30px 30px 30px 30px;
}

.business-vids iframe,
.wedding-vids iframe,
.more-vids iframe {
  width: calc(33.33% - 10px); /* Adjust the width and spacing between iframes */
  margin-bottom: 10px; /* Adjust the vertical spacing between iframes */
}

/* Add media query for mobile responsiveness */
@media (max-width: 767px) {
  .business-vids iframe,
  .wedding-vids iframe,
  .more-vids iframe {
    width: 100%; /* Set width to 100% for mobile view */
  }
}

/* About Us */

.about-us-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.logo-avatars {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  padding: 50px;
}

.logo-avatars img {
  max-width: 80%;
  margin: 0 70px;
  border-radius: 50%;
}

.about-us-container p {
  font-size: 1.5em;
  line-height: 1.8;
  font-family: "Open Sans", sans-serif;
  text-indent: 60px;
}

.sign-off p {
  text-indent: 1000px;
}

.final-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px;
}

.final-logo img {
  max-width: 25%;
  height: auto;
}

/* Responsive Styles */
@media (max-width: 767px) {
  .logo-avatars {
    flex-direction: column;
  }

  .logo-avatars img {
    max-width: 100%;
    margin: 10px 0;
  }

  .about-us-container p {
    font-size: 1.2em;
  }

  .sign-off {
    text-indent: 1000px;
  }

  .final-logo {
    max-width: 100%; /* Make sure the image doesn't exceed its container */
    height: auto; /* Maintain aspect ratio */
  }
}

/* Sign Up */

.social-links {
  width: 300px;
}
.instagram-logo,
.youtube-logo,
.email-icon {
  width: 100px;
  height: auto;
}
